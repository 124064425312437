import { signal, Signal } from '@preact/signals-core'
import { polygon } from '@turf/helpers'
import { geoMapStyle, schMapStyle } from 'assets/map'
import { useEffect, useMemo, useRef } from 'react'
import ReactMapGL, { MapEvent, MapRef, ViewportProps } from 'react-map-gl'
import { INTERACTIVE_LAYER_IDS, loadMissingImage } from 'services'
import { transformRequest } from 'utils'
import { Loader } from 'components'
import { ContentType } from 'types'
import { MapDataSignal, PanelSignal } from 'pages/home/panels/PanelsManager'
import InfoListPanel from 'pages/home/panels/info/InfoListPanel'
import InfoSinglePanel from 'pages/home/panels/info/InfoSinglePanel'
import GeoLayers from './GeoLayers'
import SchLayers from './SchLayers'
import MapControls from './controls/MapControls'

type MapProps = {
  data: Signal<Omit<ContentType, 'id'>>
}

export default function Map({ data }: MapProps) {
  const LoadingSignal = useMemo(() => signal<boolean>(true), [])
  const mapRef = useRef<MapRef>()
  const { type, vp } = data.value

  const onViewportChange = (newViewport: ViewportProps) => {
    const newVp = { ...newViewport, transitionDuration: 0 }
    const bounds = mapRef.current?.getMap().getBounds()
    if (bounds) {
      const bbox = polygon([[
        [bounds.getNorthWest().lng, bounds.getNorthWest().lat],
        [bounds.getNorthEast().lng, bounds.getNorthEast().lat],
        [bounds.getSouthEast().lng, bounds.getSouthEast().lat],
        [bounds.getSouthWest().lng, bounds.getSouthWest().lat],
        [bounds.getNorthWest().lng, bounds.getNorthWest().lat],
      ]]).geometry
      data.value = { type, vp: newVp, bbox }
    } else {
      data.value = { type, vp: newVp }
    }
  }

  const handleClickFeatures = (e: MapEvent) => {
    MapDataSignal.targetedObject.value = undefined
    const filteredFeature = e.features?.filter(
      (feature, index, arr) => arr.findIndex(f => f.properties.id === feature.properties.id) === index,
    )
    if (filteredFeature?.length === 0) return
    if (filteredFeature?.length === 1) {
      PanelSignal.value = (
        <InfoSinglePanel feature={filteredFeature[0]} />
      )
    } else if (filteredFeature?.length > 1) {
      PanelSignal.value = (
        <InfoListPanel data={e.features.filter(
          (feature, index, arr) => arr.findIndex(f => f.properties.id === feature.properties.id) === index,
        )}
        />
      )
    }
  }

  // Display loader when map is loading data
  useEffect(() => {
    if (!mapRef.current) return undefined
    const startLoading = () => { LoadingSignal.value = true }
    const stopLoading = () => { LoadingSignal.value = false }

    mapRef.current.getMap().on('idle', stopLoading)
    mapRef.current.getMap().on('sourcedataloading', startLoading)

    return () => {
      stopLoading()
      mapRef.current?.getMap().off('idle', stopLoading)
      mapRef.current?.getMap().off('sourcedataloading', startLoading)
    }
  }, [type])

  useEffect(() => {
    const map = mapRef?.current?.getMap()
    const onStyleImageMissing = (e: {id: string}) => { loadMissingImage(mapRef, e.id) }
    map.on('styleimagemissing', onStyleImageMissing)
    return () => { map.off('styleimagemissing', onStyleImageMissing) }
  }, [])

  useEffect(() => {
    const map = mapRef?.current?.getMap()
    const style = type === 'geo' ? geoMapStyle : schMapStyle
    map.setStyle(style)
  }, [type])

  return (
    <>
      {LoadingSignal.value && <div className="content-loading-wrapper"><Loader variant="small" /></div>}
      <ReactMapGL
        {...vp}
        width="100%"
        height="100%"
        onViewportChange={onViewportChange}
        clickRadius={2}
        transformRequest={transformRequest}
        interactiveLayerIds={INTERACTIVE_LAYER_IDS}
        ref={mapRef}
        mapStyle={schMapStyle}
        onHover={e => {
          MapDataSignal.hoveredObjects.value = [...e.features, MapDataSignal.targetedObject.value].filter(Boolean)
        }}
        onClick={handleClickFeatures}
      >
        {type === 'geo' ? <GeoLayers mapRef={mapRef} /> : <SchLayers mapRef={mapRef} />}
        <MapControls data={data} />
      </ReactMapGL>
    </>
  )
}
