/* eslint-disable no-unused-expressions */
import { useState, MouseEvent } from 'react'
import moment from 'moment'
import terms from 'assets/terms'
import { ReactComponent as FavoriteIcon } from 'assets/icons/favorite.svg'
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg'
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg'
import { useMountEffect } from 'utils'
import { Views } from 'types'
import {
  deleteView,
  getContentForParam,
  getContentsFromURL,
  getLabelsForIndexes,
  getViews,
  LayersSignal,
  ViewSignal,
} from 'services'
import { Button, ButtonStyle, ConfirmModal, ModalSignal, TextInput } from 'components'
import { ContentSignals } from 'pages/home/contents/ContentsManager'

import './ViewPanel.scss'

export default function ViewPanel() {
  const [filter, setFilter] = useState('')
  const [toogledContents, setToogledContents] = useState<string[]>([])
  const filteredViews = ViewSignal.value?.filter(view => view.name.toLowerCase().includes(filter))

  const handleSortViews = (value: string) => { setFilter(value.toLowerCase()) }

  const handleLoadView = (view: Views) => () => {
    const { layers, left, right } = getContentsFromURL(view.content)

    LayersSignal.value = layers ? layers.split(',') : []
    ContentSignals.left.data.value = left ? getContentForParam(left) : null
    ContentSignals.right.data.value = right ? getContentForParam(right) : null
  }

  const handleToggleContent = (id: string) => () => {
    if (toogledContents.includes(id)) {
      setToogledContents(toogledContents.filter(content => content !== id))
    } else {
      setToogledContents([...toogledContents, id])
    }
  }

  const handleFavorite = () => (e: MouseEvent) => {
    e.stopPropagation()
    /* to implement */
  }

  const handleDelete = (view: Views) => (e: MouseEvent) => {
    e.stopPropagation()

    const closeModal = () => { ModalSignal.value = undefined }
    ModalSignal.value = (
      <ConfirmModal
        title="Supprimer la vue ?"
        handleClose={closeModal}
        handleValidate={async () => {
          await deleteView(view.id)
          closeModal()
        }}
      />
    )
  }

  const getLayersForView = (view: Views) => {
    const { layers } = getContentsFromURL(view.content)
    return getLabelsForIndexes(layers?.split(','))?.join(', ')
  }

  const getViewType = (view: Views) => {
    const { left, right } = getContentsFromURL(view.content)
    const leftTypeLabel = terms.Contents[left?.split(',')[0]]
    const rightTypeLabel = terms.Contents[right?.split(',')[0]]

    if (leftTypeLabel && rightTypeLabel) return `${leftTypeLabel} / ${rightTypeLabel}`
    if (left) return leftTypeLabel
    if (right) return rightTypeLabel

    return ''
  }

  useMountEffect(() => { getViews() })

  return (
    <>
      <h3 className="panel-title">{terms.Panels.Views.title}</h3>
      <div className="views-panel">
        <TextInput
          label={terms.Common.search}
          onChange={handleSortViews}
          LeftIcon={<SearchIcon />}
        />
        <table className="views">
          <thead>
            <tr>
              <th><div className="name">{terms.Panels.Views.name}</div></th>
              <th><div className="layers">{terms.Panels.Views.layers}</div></th>
              <th><div className="date">{terms.Panels.Views.creationDate}</div></th>
              <th><div className="actions">{terms.Panels.Views.actions}</div></th>
            </tr>
          </thead>
          <tbody>
            {filteredViews?.map(view => (
              <tr className="item" key={view.id} onClick={handleLoadView(view)}>
                <td>
                  <div className="name">
                    {view.name}
                    <span>{`(${getViewType(view)})`}</span>
                  </div>
                </td>
                <td>
                  <div className="layers">
                    <span className={`${toogledContents.includes(view.id) ? 'expanded' : ''}`}>
                      {getLayersForView(view)}
                    </span>
                    {getLayersForView(view) && (
                    <Button
                      style={ButtonStyle.borderLess}
                      text={`${toogledContents.includes(view.id) ? terms.Common.showLess : terms.Common.showMore}`}
                      onClick={handleToggleContent(view.id)}
                    />
                    )}
                  </div>
                </td>
                <td><div className="date">{moment(view.creation_date).format('DD/MM/YYYY')}</div></td>
                <td>
                  <div className="actions flex-center">
                    <Button
                      style={ButtonStyle.borderLess}
                      text=""
                      icon={<FavoriteIcon />}
                      disabled
                      onClick={handleFavorite()}
                      isAsync
                    />
                    <Button
                      style={ButtonStyle.borderLessPrimary}
                      text=""
                      icon={<DeleteIcon />}
                      onClick={handleDelete(view)}
                      isAsync
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}
