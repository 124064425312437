import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-left.svg'
import { ReactComponent as FavoriteIcon } from 'assets/icons/favorite.svg'
import { Collection } from 'types'
import { Accordion, Button, ButtonStyle } from 'components'

import './CollectionDetail.scss'

type Props = {
  collection: Collection
  onClose: () => void
}

export default function CollectionDetail({ collection, onClose }: Props) {
  const handleFavorite = () => {
    // to implement
  }

  return (
    <div className="collection-detail hide-scroll">
      <h3>
        <Button
          style={ButtonStyle.borderLess}
          text=""
          icon={<ArrowLeftIcon />}
          onClick={onClose}
        />
        <p>{collection.nom_collection_gep}</p>
        <Button
          style={ButtonStyle.borderLess}
          text=""
          icon={<FavoriteIcon />}
          onClick={handleFavorite}
          disabled
        />
      </h3>
      <div className="separator" />
      <Accordion title="IS de la collection" defaultExpanded>
        {collection.infos_is.map(is => (
          <div key={is.id} className="is-item">
            <p>
              <b>Nom de l’IS : </b>
              {is.libelle}
            </p>
            <p>
              <b>Type : </b>
              {is.type}
            </p>
            <p>
              <b>Technologie : </b>
              {is.poste_technologie_libelle}
            </p>
            <p>
              <b>Ferrolocalisation : </b>
              {`${is.ligne_id} - ${is.localisation}`}
            </p>
            <p>
              <b>Gaia : </b>
              {is.gaia_match ? 'Oui' : 'Non'}
            </p>
            {is.gaia_match && (
              <p>
                <b>IHM : </b>
                {is.poste_ihms_libelles.filter(Boolean).length
                  ? is.poste_ihms_libelles.filter(Boolean).join(', ') : 'Aucun'}
              </p>
            )}
            <div className="separator" />
          </div>
        ))}
      </Accordion>
      <Accordion title="Interactions" defaultExpanded>
        <div className="is-item">
          <p>
            <b>Collection(s) encadrante(s) : </b>
            {
              collection.collections_encadrantes.filter(Boolean).length
                ? collection.collections_encadrantes.filter(Boolean).join(', ')
                : 'Aucune'
            }
          </p>
        </div>
      </Accordion>
      <div className="separator" />
      <Accordion title="Indices" defaultExpanded>
        <table>
          <thead>
            <tr>
              <th>Indice</th>
              <th>Statut</th>
              <th>Projet / Travaux</th>
            </tr>
          </thead>
          <tbody>
            {collection.indices_gep.map(indice => (
              <tr key={indice.id}>
                <td>{indice.indice}</td>
                <td>{indice.categorie}</td>
                <td>{indice.objet}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Accordion>
    </div>
  )
}
